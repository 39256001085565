import { px } from '@/layout/styles/classes';
import { SectionTag } from '@/SectionTag';
import { Video } from '@/Video';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const Back = styled.section`
    ${px};
    padding-top: 64px;
    padding-bottom: 80px;

    @media (min-width: 1024px) {
        padding-top: 88px;
        padding-bottom: 130px;
    }
`;

const Info = styled.div`
    > div {
        h3 {
            margin: 0 0 8px;
            color: ${({ theme }) => theme.colors.purple2};
            text-transform: capitalize;
        }

        > p {
            margin: 0;
            max-width: ${({ theme }) => theme.sizes.md};
        }
    }
    > p {
        margin: 0;
    }

    @media (min-width: 1024px) {
        display: flex;
        align-items: flex-start;
        max-width: 1064px;
        margin: 0 auto;

        > div {
            margin-right: 152px;
        }

        > p {
            max-width: 360px;
            margin-top: 40px;
        }
    }
`;

const videoStyles = css`
    margin: 64px auto 0;

    &,
    > * {
        aspect-ratio: 343 / 210;
    }

    @media (min-width: 1024px) {
        max-width: 720px;
        margin-top: 80px;

        &,
        > * {
            aspect-ratio: 738 / 453;
        }
    }

    @media (min-width: 1150px) {
        max-width: 738px;
        margin-top: 80px;
    }
`;

export const GivingBack = () => {
    return (
        <Back>
            <Info>
                <div>
                    <SectionTag title="giving back" bc="#FCE4F2" tc="#B8509B" />
                    <h3>Giving Back to the Community</h3>
                </div>
                <p>
                    Our detailed approach to dentistry and comfort makes us different, with{' '}
                    {new Date().getFullYear() - 1997} years of experience working with families to
                    ensure the best possible service and technologies available to dentists today.
                </p>
            </Info>
            <Video videoId="1OG8jjV5dvo" css={videoStyles}>
                <StaticImage
                    src="../../../assets/images/about/meet-dr-kent/02.jpg"
                    alt="dentistry from heart"
                    width={737}
                />
            </Video>
        </Back>
    );
};
