import lq from '@a/icons/left-quote.svg';
import rq from '@a/icons/right-quote.svg';
import star from '@a/icons/star.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { flexCenter, flexSpace } from './layout/styles/classes';

const Review = styled.div<{ first?: boolean }>`
    background: ${({ theme }) => theme.colors.white};
    border-radius: 15px;
    padding: 16px;
    border: 1px solid ${({ theme }) => theme.colors.gray2};
    max-width: ${({ theme }) => theme.sizes.md};

    > div {
        ${flexSpace};

        > p {
            margin: 0 auto 0 0;
            color: ${({ theme }) => theme.colors.orange1};
            font-weight: 600;
            text-transform: capitalize;
        }

        > img {
            margin: 0 2px;
            width: 13.5px;
        }
    }

    > p {
        font-size: 0.875rem;
        line-height: 175.18%;

        :first-of-type {
            margin: 16px 0;
        }

        :last-of-type {
            ${flexCenter};
            display: flex;
            color: ${({ theme }) => theme.colors.gray4};
            margin: 0;

            ::before {
                content: '';
                border-top: 1px solid ${({ theme }) => theme.colors.gray4};
                width: 80%;
                margin-right: 16px;
            }

            > span {
                flex-shrink: 0;
            }
        }
    }

    @media (min-width: 1024px) {
        ${({ first }) =>
            first
                ? css`
                      box-shadow: 0px 24px 56px rgba(77, 31, 119, 0.12),
                          0px 13.2664px 16.8824px rgba(77, 31, 119, 0.0221485);
                      padding: 40px;
                      border: none;
                      position: relative;

                      ::before {
                          content: url(${lq});
                          position: absolute;
                          top: 27px;
                          left: -46px;
                      }

                      ::after {
                          content: url(${rq});
                          position: absolute;
                          bottom: 16px;
                          right: -46px;
                      }

                      > div {
                          > p {
                              font-size: 1.5rem;
                          }

                          > img {
                              width: 26.5px;
                              margin: 0 3px;
                          }
                      }

                      > p {
                          font-size: 1rem;

                          :first-of-type {
                              margin: 24px 0;
                          }
                      }
                  `
                : css`
                      > p:first-of-type {
                          -webkit-line-clamp: 6;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                          display: -webkit-box;
                      }
                  `};
    }
`;

type ReviewProps = {
    first?: boolean;
    name: string;
    text: string;
    date: string;
};
export const SingleReview = ({ first, name, text, date }: ReviewProps) => {
    return (
        <Review first={first}>
            <div>
                <p>{name}</p>
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
            </div>
            <p>{text}</p>
            <p>
                <span>{date}</span>
            </p>
        </Review>
    );
};
