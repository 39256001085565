import { PrimaryBtn } from '@/Buttons/PrimaryBtn';
import CLink from '@/CLink';
import { px } from '@/layout/styles/classes';
import { SectionTag } from '@/SectionTag';
import { SingleReview } from '@/SingleReview';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';

const StyledTestimonials = styled.section`
    ${px};
    padding-top: 64px;
    padding-bottom: 64px;
`;

const Info = styled.div`
    max-width: 1064px;
    margin: 0 auto;

    > h3 {
        margin: 0 0 64px;
    }

    @media (min-width: 1024px) {
        > h3 {
            margin: 0;
        }
    }
`;

const Reviews = styled.div`
    & + a {
        width: fit-content;
        margin: 64px auto 0;
        display: flex;
    }

    > div {
        margin-bottom: 32px;
    }

    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: row;
        gap: 32px;

        > div {
            margin: 0;
        }
    }

    @media (min-width: 1024px) {
        grid-template-columns: minmax(0, 584px) 236px 236px;
        grid-template-rows: 16px 184px 21px 265px;
        align-items: start;
        justify-content: center;
        gap: 60px 40px;

        > div {
            :first-of-type {
                grid-row: span 4;
                align-self: center;
            }

            :nth-of-type(3) {
                grid-row: 2 / span 2;
                grid-column: 3;
                align-self: end;
            }

            :nth-of-type(4) {
                grid-row: 3 / span 2;
                grid-column: 2;
            }

            :nth-of-type(5) {
                grid-row: 4;
                grid-column: 3;
            }
        }
    }

    @media (min-width: 1150px) {
        margin-left: 54px;
    }

    @media (min-width: 1366px) {
        gap: 60px 50px;
        margin-left: 88px;
    }
`;

export const Testimonials = () => {
    type DataTypes = {
        allGooglePlacesReview: {
            nodes: {
                relative_time_description: string;
                author_name: string;
                text: string;
            }[];
        };
    };

    const data: DataTypes = useStaticQuery(graphql`
        query googleReviews {
            allGooglePlacesReview(sort: { fields: time, order: DESC }) {
                nodes {
                    relative_time_description
                    author_name
                    text
                }
            }
        }
    `);

    const reviews = [
        {
            author_name: 'mike calabrese',
            relative_time_description: '7 years ago',
            text: 'Dr Nuttall has been my dentist for over 20 years...  he has a great office staff that is very professional and helpful. I know going to the dentist in not one’s favorite thing to do but Nuttall Smiles make these necessary visits pleasant. Dr Nuttall is one of the best if not the best dentist in the Auburn area, I would not hesitate in recommending him to newcomers to Auburn or someone wanting to change dentists. Keep up the good work.',
        },
        ...data.allGooglePlacesReview.nodes.slice(0, 4),
    ];

    return (
        <StyledTestimonials>
            <Info>
                <SectionTag title="testimonials" bc="#DEEFAC" tc="#678D29" />
                <h3>What Our Patients Say</h3>
            </Info>
            <Reviews>
                {reviews.map((review, i) => (
                    <SingleReview
                        text={review.text}
                        name={`${review.author_name.split(' ')[0]} ${
                            review.author_name.split(' ').length > 1
                                ? `${review.author_name.split(' ')[1][0]}.`
                                : ''
                        }`}
                        date={review.relative_time_description}
                        key={i}
                        first={i === 0}
                    />
                ))}
            </Reviews>
            <PrimaryBtn
                as={CLink}
                to="https://www.google.com/search?q=nuttall+smiles&rlz=1C1CHFX_enUS694US696&oq=nuttall+smiles&aqs=chrome..69i57j69i60l3j0.3879j0j1&sourceid=chrome&ie=UTF-8#lrd=0x5490f7d902f1a0c1:0x2e5fdc30a60c0dc9,1,"
            >
                See our latest Google reviews
            </PrimaryBtn>
        </StyledTestimonials>
    );
};
