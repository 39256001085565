import { PrimaryBtn } from '@/Buttons/PrimaryBtn';
import CLink from '@/CLink';
import { SectionTag } from '@/SectionTag';
import { Video } from '@/Video';
import { info } from '@/layout/NavLinks';
import { textSm } from '@/layout/styles/Typography';
import { flexStart, px } from '@/layout/styles/classes';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import '@fontsource/vast-shadow';
import { graphql, useStaticQuery } from 'gatsby';
import {
    GatsbyImage,
    IGatsbyImageData,
    ImageDataLike,
    StaticImage,
    getImage,
} from 'gatsby-plugin-image';
import scroll from '../../../assets/images/homepage/hero/scroll-down.svg';
import phone from '../../../assets/images/icons/phone.svg';
import pin from '../../../assets/images/icons/pin.svg';

const StyledHero = styled.section`
    ${px};
    padding-top: 20px;
    padding-bottom: 26px;

    @media (min-width: 768px) {
        background-size: cover;
        background-position: top left;
    }

    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 0.54fr 0.46fr;
        grid-template-rows: auto 203px;
        gap: 8px;
        padding-top: 0;
        padding-bottom: 48px;
    }

    @media (min-width: 1366px) {
        position: relative;

        ::before {
            content: url(${scroll});
            position: absolute;
            grid-column: 1;
            grid-row: 2;
            align-self: end;
            margin: 0 0 42px -24px;
        }
    }

    @media (min-width: 1600px) {
        grid-template-columns: 1fr auto;
        grid-template-rows: auto 319px;
        margin: -20px auto 0;
        max-width: 1920px;
    }
`;

const Intro = styled.div`
    margin-bottom: 24px;

    > h1 {
        margin: 4px 0 16px;
        max-width: 580px;
        position: relative;
        text-transform: none;
        font-family: 'Domine', 'Comic Sans MS', serif;

        > span {
            color: ${({ theme }) => theme.colors.purple1};
        }

        ::before {
            content: '';
            position: absolute;
            left: -3px;
            top: 29px;
            width: 215px;
            height: 24.5px;
            background-color: ${({ theme }) => theme.colors.orange2};
            z-index: -1;
        }
    }

    > .home-hero-caption {
        display: none;
        color: ${({ theme }) => theme.colors.gray4};
        margin: 0 0 16px;
        max-width: 522px;
    }

    > a:last-of-type {
        ${textSm};
        display: none;
        text-decoration: underline;
        margin-top: 14px;
        transition: color 0.3s ease-in-out;

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.purple1};
        }

        > img {
            margin-right: 13px;
        }
    }

    @media (min-width: 1024px) {
        margin-bottom: 0;
        align-self: end;

        > h1 {
            font-size: 3.25rem;

            ::before {
                width: 396px;
                top: 32px;
                left: -13.76px;
                height: 40px;
            }
        }
    }

    @media (min-width: 1150px) {
        > h1 {
            ::after {
                content: '';
                position: absolute;
                border-top: 3px solid ${({ theme }) => theme.colors.purple1};
                max-width: 237px;
                width: 100%;
                right: -50px;
                bottom: 16px;
                z-index: -1;
            }
        }

        > .home-hero-caption {
            display: block;
        }
    }

    @media (min-width: 1366px) {
        > a:last-of-type {
            display: flex;
            width: fit-content;
        }
    }

    @media (min-width: 1600px) {
        transform: translateY(12%);
    }
`;

const Dr = styled.div`
    ${flexStart};
    margin: 0 0 16px;
    position: relative;

    > a {
        color: ${({ theme }) => theme.colors.orange1};
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 175.18%;
        margin: 0;
        text-decoration-line: underline;
        transition: color 0.3s ease-in-out;

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.orange2};
        }
    }

    ::before {
        content: '';
        position: absolute;
        left: 5.87px;
        top: 13px;
        width: 24.25px;
        height: 25.1px;
        background: ${({ theme }) => theme.colors.purple1};
        opacity: 0.7;
        filter: blur(25px);
    }

    > .gatsby-image-wrapper {
        border-radius: 50%;
        width: 36px;
        height: 36px;
        margin-right: 18px;
        opacity: 0.9999;
    }

    @media (min-width: 1024px) {
        margin-bottom: 24px;

        ::before {
            left: 8.29px;
            top: 18.33px;
            width: 34.24px;
            height: 35.44px;
        }

        > a {
            font-size: 1rem;
        }

        > .gatsby-image-wrapper {
            width: 50px;
            height: 50px;
        }
    }

    @media (min-width: 1800px) {
        > .gatsby-image-wrapper {
            width: 64px;
            height: 64px;
        }
    }
`;

const Box = styled.div`
    margin-bottom: 24px;
    max-width: ${({ theme }) => theme.sizes.md};
    aspect-ratio: 343 / 420;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    opacity: 0.9999;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
            0deg,
            #000000 -3.48%,
            rgba(0, 0, 0, 0.46) 33.27%,
            rgba(0, 0, 0, 0) 55.69%
        );
        z-index: 2;
    }

    @media (min-width: 1024px) {
        grid-row: span 2;
        aspect-ratio: 590 / 747;
        margin-bottom: 0;
        max-width: 630px;
        align-self: end;
    }
`;

const Slider = styled.div`
    display: grid;

    > div {
        grid-area: 1/1;
        animation: slide 21s infinite;
        opacity: 0;

        /*

        1s fadein 2s visible i.e 3s * 7 images = 21s duration

        keyframes 1s = 100 / images i.e. 100/7=4.76

        delay = (1s fadein + 2s visible ) * position - 1 = delay
        for second slide 3 * (2 - 1)
        for third slide 3 * (3 - 1)
        and so on

        */

        @keyframes slide {
            0% {
                opacity: 0;
                animation-timing-function: ease-in;
            }
            4.76% {
                opacity: 1;
                animation-timing-function: ease-out;
            }
            14.28% {
                opacity: 1;
            }
            19.04% {
                opacity: 0;
            }
            100% {
                opacity: 0;
            }
        }

        :first-of-type {
            animation-delay: 0s;
        }

        :nth-of-type(2) {
            animation-delay: 3s;
        }

        :nth-of-type(3) {
            animation-delay: 6s;
        }

        :nth-of-type(4) {
            animation-delay: 9s;
        }

        :nth-of-type(5) {
            animation-delay: 12s;
        }

        :nth-of-type(6) {
            animation-delay: 15s;
        }

        :last-of-type {
            animation-delay: 18s;
        }
    }
`;

const Celebrate = styled.div`
    z-index: 3;
    left: 50%;
    bottom: 24px;
    width: 95%;
    max-width: 446px;
    display: block;
    transform: translateX(-50%);
    position: absolute;

    > p {
        position: absolute;
        color: white;
        margin: 0;

        :first-of-type {
            font-family: 'Vast Shadow', 'Domine', serif;
            font-size: min(
                15.1vw,
                83px
            ); //based on max width is at 550px viewport. so max font size / 550 we get vw unit
            top: 24.6%;
            left: 35.1%;
        }

        :last-of-type {
            font-weight: 500;
            left: 64%;
            bottom: 5.6%;
            font-size: min(
                6.2vw,
                34px
            ); //based on max width is at 550px viewport. so max font size / 550 we get vw unit
            transform: rotate(-25deg);

            @media (max-width: 450px) {
                bottom: 4%;
                left: 64.5%;
            }

            @media (max-width: 390px) {
                bottom: 3.5%;
            }
        }
    }

    @media (min-width: 1024px) {
        bottom: 48px;
    }
`;

const videoStyles = css`
    &,
    & > * {
        height: min(50vw, 308px);
        aspect-ratio: 343 / 203;
    }

    @media (min-width: 1024px) {
        max-width: 292px;
        justify-self: end;

        &,
        & > * {
            max-height: 203px;
            aspect-ratio: 292 / 203;
        }
    }

    @media (min-width: 1600px) {
        max-width: 459px;

        &,
        & > * {
            height: min(50vw, 319px);
            aspect-ratio: 343 / 203;
            max-height: 319px;
        }
    }

    @media (min-width: 1800px) {
        max-width: 539px;
    }

    @supports (aspect-ratio: 1/1) {
        &,
        & > * {
            height: unset;
        }
    }
`;

export const Hero = () => {
    const data = useStaticQuery(graphql`
        query heroImages {
            allFile(
                filter: { relativeDirectory: { eq: "homepage/hero/slider" } }
                sort: { order: ASC, fields: name }
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(
                            width: 630
                            quality: 75
                            breakpoints: [300, 350, 400, 500, 630]
                        )
                    }
                }
            }
        }
    `);

    return (
        <StyledHero>
            <Intro>
                <SectionTag title="nuttall smiles" bc="#CBB9FF" tc="#53399E" />
                <h1>
                    Changing the way <span>you</span> and <span>your family</span> feel about{' '}
                    <span>dentistry</span>
                </h1>
                <p className="home-hero-caption">
                    Welcome to Nuttall Smiles, your comprehensive resource for quality dentistry in
                    Auburn, Washington.
                </p>
                <Dr>
                    <StaticImage
                        src="../../../assets/images/dr-nuttall.jpg"
                        alt="Dr. Kent Nuttall, DMD"
                        width={64}
                        height={64}
                        quality={100}
                        loading="eager"
                    />
                    <CLink to="#about-dr">Dr. Kent Nuttall, DMD</CLink>
                </Dr>
                <PrimaryBtn as={CLink} to={info.phone.link} icon="true">
                    <img src={phone} alt="" />
                    Call us at {info.phone.text}
                </PrimaryBtn>
                <CLink to={info.address.link}>
                    <img src={pin} alt="" />
                    {info.address.text}
                </CLink>
            </Intro>
            <Box>
                <Slider>
                    {data.allFile.nodes.map((image: ImageDataLike, i: number) => (
                        <GatsbyImage
                            image={getImage(image) as IGatsbyImageData}
                            alt="nuttall smiles"
                            key={i}
                        />
                    ))}
                </Slider>

                <Celebrate>
                    <p>{new Date().getFullYear() - 1997}</p>
                    <StaticImage
                        src="../../../assets/images/homepage/hero/celebrate.png"
                        alt={`celebrating ${
                            new Date().getFullYear() - 1997
                        } years of changing lives since 1997`}
                        width={446}
                        height={280}
                    />
                    <p>{new Date().getFullYear().toString().slice(2)}</p>
                </Celebrate>
            </Box>
            <Video videoId="nFRPjEjteVk" css={videoStyles}>
                <StaticImage
                    src="../../../assets/images/homepage/hero/thumbnail.jpg"
                    alt="intro"
                    width={539}
                    quality={100}
                    loading="eager"
                />
            </Video>
        </StyledHero>
    );
};
