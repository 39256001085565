import { flexCenter } from '@/layout/styles/classes';
import { SectionTag } from '@/SectionTag';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import loadable from '@loadable/component';
import useIntersectionObserver from '@s/hooks/useIntersectionObserver';
import { useRef } from 'react';
// eslint-disable-next-line
import 'swiper/css/navigation';
// eslint-disable-next-line
import "swiper/css/pagination";
import squares from '../../../assets/images/homepage/stories/other/squares.svg';
import { Info } from './Services';

const StyledServices = styled.section`
overflow: hidden;

    @media (min-width: 1024px) {
        display: grid;
    }

    @media (min-width: 1366px) {
        position: relative;

        ::before {
            content: '';
            position: absolute;
            top: -16px;
            right: 60px;
            width: 62px;
            height: 194px;
            background: url(${squares}) no-repeat;
            z-index: 2;
        }
    }
`;

const TheInfo = styled(Info)`
    padding-bottom: 48px;

    @media (min-width: 1024px) {
        padding-right: 32px;

        > p {
            max-width: 414px;
            margin-left: 64px;
        }
    }

    @media (min-width: 1150px) {
        > p {
            max-width: 360px;
        }
    }
`;

const Fallback = () => (
    <div
        css={css`
            ${flexCenter};
            min-height: 215px;
            aspect-ratio: 483 / 240;
            grid-area: 1/1;
            align-self: end;
            max-height: 350px;

            @media (min-width: 1024px) {
                height: 450px;
            }
        `}
    >
        Loading...
    </div>
);

const SliderComponent = loadable(() => import('../StoriesSlider'), {
    resolveComponent: components => components.StoriesSlider,
    fallback: <Fallback />,
});

export const Stories = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(ref, {
        freezeOnceVisible: true,
        rootMargin: '300px 0px 0px',
    });
    const isVisible = !!entry?.isIntersecting;

    return (
        <StyledServices ref={ref}>
            <TheInfo>
                <div>
                    <SectionTag title="stories" bc="#474F74" tc="#81ABD1" />
                    <h3>Our Customers’ Stories</h3>
                </div>
                <p>
                    “Best experience I have ever had at a dental practice. Everyone from the front
                    office staff, dental hygienist, dental assistants to Dr. Nuttall are so kind and
                    friendly” - Robbin S.
                </p>
            </TheInfo>
            {isVisible ? <SliderComponent /> : <Fallback />}
        </StyledServices>
    );
};
