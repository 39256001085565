import { px } from '@/layout/styles/classes';
import { PaymentTypes } from '@/PaymentTypes';
import styled from '@emotion/styled';

const StyledPayments = styled.section`
    ${px};
    background-color: ${({ theme }) => theme.colors.orange4};
    padding-top: 40px;
    padding-bottom: 40px;

    > h3 {
        margin: 0 0 12px;
    }

    @media (min-width: 600px) {
        > h3 {
            margin-bottom: 48px;
        }
    }

    @media (min-width: 1024px) {
        padding-top: 88px;
        padding-bottom: 88px;

        > h3 {
            margin: 0 auto 48px;
            max-width: 1064px;
        }
    }
`;

export const Payments = () => {
    return (
        <StyledPayments>
            <h3>payments we accept</h3>
            <PaymentTypes />
        </StyledPayments>
    );
};
