import { AboutDr } from '@/homepage/AboutDr';
import { Affiliations } from '@/homepage/Affiliations';
import { GivingBack } from '@/homepage/GivingBack';
import { Hero } from '@/homepage/Hero';
import { Payments } from '@/homepage/Payments';
import { Services } from '@/homepage/Services';
import { Stories } from '@/homepage/Stories';
import { Technology } from '@/homepage/Technology';
import { Testimonials } from '@/homepage/Testimonials';
import { Who } from '@/homepage/Who';
import { Seo } from '@/layout/Seo';
import { Fragment } from 'react';

const Home: Page = () => (
    <Fragment>
        <Seo />
        <Hero />
        <Affiliations />
        <Who />
        <Services />
        <AboutDr />
        <Technology />
        <Stories />
        <Testimonials />
        <GivingBack />
        <Payments />
    </Fragment>
);

export default Home;
