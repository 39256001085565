import { flexCenter, px } from '@/layout/styles/classes';
import { theme } from '@/layout/styles/GlobalStyles';
import { SectionTag } from '@/SectionTag';
import { Video } from '@/Video';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const StyledWho = styled.section`
    ${px};
    padding-top: 64px;
    padding-bottom: 64px;

    @media (min-width: 1024px) {
        padding-top: 96px;
        padding-bottom: 96px;
    }

    @media (min-width: 1366px) {
        position: relative;

        ::before {
            content: '';
            position: absolute;
            left: 50%;
            top: calc(50% - 16px);
            background-color: ${({ theme }) => theme.colors.orange2};
            width: 61px;
            height: 61px;
            z-index: -1;
            border-radius: 10px;
        }
    }
`;

const Article = styled.article`
    strong {
        color: #84642b;
    }

    margin-bottom: 64px;

    :last-of-type {
        margin-bottom: 0px;
    }

    > div:last-of-type {
        margin-top: 24px;

        > h3 {
            margin: 0 0 8px;
        }

        > p {
            margin: 0;
            max-width: ${({ theme }) => theme.sizes.lg};
        }
    }

    @media (min-width: 1024px) {
        ${flexCenter};

        &.flip {
            > div:last-of-type {
                order: -1;
                margin-left: 0;
                margin-right: calc(8px + ${({ theme }) => theme.spacing.xl});
            }
        }

        > div:last-of-type {
            margin-top: 0;
            margin-left: ${({ theme }) => theme.spacing.xl};
            max-width: 440px;
        }
    }
`;
const video = css`
    &,
    > * {
        height: min(50vw, 295px);
        aspect-ratio: 343 / 194;
    }

    @media (min-width: 1024px) {
        &,
        > * {
            height: min(50vw, 354px);
            max-width: 542px;
            aspect-ratio: 542 / 354;
        }
    }

    @supports (aspect-ratio: 1/1) {
        &,
        & > * {
            height: unset;
        }
    }

    @media (min-width: 1366px) {
        position: relative;

        ::before,
        ::after {
            content: '';
            position: absolute;
            z-index: -1;
            width: 40px;
            height: 40px;
            border-radius: 10px;
        }

        ::before {
            right: -48px;
            bottom: 25px;
            background-color: ${theme.colors.purple3};
        }

        ::after {
            right: 28.48px;
            bottom: -30.43px;
            background-color: ${theme.colors.orange3};
        }
    }
`;

const Wrapper = styled.div`
    max-width: ${({ theme }) => theme.sizes.md};

    > .gatsby-image-wrapper {
        border-radius: 15px;
        aspect-ratio: 343 /219;
        opacity: 0.9999;
    }

    @media (min-width: 1366px) {
        position: relative;

        ::before,
        ::after {
            content: '';
            position: absolute;
            z-index: -1;
            width: 28px;
            height: 28px;
            border-radius: 5px;
        }

        ::before {
            left: -36px;
            top: 4px;
            background-color: ${theme.colors.purple3};
        }

        ::after {
            top: -36px;
            left: 82px;
            background-color: ${theme.colors.orange3};
        }
    }
`;

export const Who = () => {
    return (
        <StyledWho>
            <Article>
                <Video videoId="HDOvSLD_rGA" css={video}>
                    <StaticImage
                        src="../../../assets/images/homepage/office-thumbnail.jpg"
                        alt="office tour"
                        width={542}
                        breakpoints={[300, 400, 542]}
                    />
                </Video>
                <div>
                    <SectionTag title="About" bc="#CAF8C3" tc="#428138" />
                    <h3>Who We Are</h3>
                    <p>
                        Welcome to Nuttall Smiles, your comprehensive resource for quality dentistry
                        in Auburn, Washington. We are happy to welcome patients of all ages, and we
                        serve our friends from the nearby areas as well, including Black Diamond,
                        Bonney Lake, Covington, East Hill-Meridian, Federal Way, Fife, Kent, Milton,
                        Pacific, and Sumner, Washington. We also have team members who speak
                        English, Spanish, and Portuguese to fit your family’s needs. We invite you
                        to contact us today to schedule your appointment with Dr. Kent Nuttall!
                    </p>
                </div>
            </Article>
            <Article className="flip">
                <Wrapper>
                    <StaticImage
                        src="../../../assets/images/homepage/different.jpg"
                        alt="who we are"
                        width={520}
                    />
                </Wrapper>
                <div>
                    <SectionTag title="Uniqueness" bc="#F6EFC8" tc="#AE8929" />
                    <h3>What Makes Us Different</h3>
                    <p>
                        Our detailed approach to dentistry and comfort makes us different, with 25
                        years of experience working with families to ensure the best possible
                        service and technologies available to dentists today.{' '}
                    </p>
                </div>
            </Article>
            <Article>
                <StaticImage
                    src="../../../assets/images/homepage/dental-patient-smiling.jpg"
                    alt="dental patient smiling"
                    width={542}
                    breakpoints={[300, 400, 542]}
                />

                <div>
                    <SectionTag title="4 REASONS" bc="#FCE4F2" tc="#562441" />
                    <h3>
                        WHY NUTTALL SMILES IS Your Family&apos;s Best Choice for a Dentist in Auburn
                    </h3>
                    <p>
                        <strong>1- Long-Standing Reputation & Stability:</strong> Same dentist, same
                        location for 28 years. Our practice has established a stellar reputation,
                        ensuring patients trust our expertise and commitment to quality care.
                        <br />
                        <strong>2- Harmonious & Dedicated Team:</strong> Our cohesive staff is
                        devoted to providing exceptional care, creating a positive atmosphere where
                        patients feel valued and well-cared for.
                        <br />
                        <strong>3- Personalized & Friendly Approach:</strong> Our outgoing
                        personalities and genuine interest in our patients foster strong
                        relationships. We help people feel like friends rather than just patients
                        through our warm and welcoming environment.
                        <br />
                        <strong>4- Commitment to Excellence & Trustworthiness:</strong> We
                        prioritize high-quality work, continuous improvement and utilizing the
                        latest technologies to ensure patients receive the best treatment options.
                        You can trust in our exceptional skill level for long-term care, creating a
                        sense of belonging and comfort for generations of families.
                    </p>
                </div>
            </Article>
        </StyledWho>
    );
};
