import { PrimaryBtn } from '@/Buttons/PrimaryBtn';
import CLink from '@/CLink';
import { getSubNavLink } from '@/layout/NavLinks';
import { flexCenter, px } from '@/layout/styles/classes';
import { SectionTag } from '@/SectionTag';
import { Video } from '@/Video';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import circles from '../../../assets/images/background-circles.svg';
import orangeSquares from '../../../assets/images/homepage/orange-squares.svg';
import purpleSquares from '../../../assets/images/homepage/purple-squares.svg';
import dots from '../../../assets/images/icons/dotted-line.svg';

const StyledDr = styled.section`
    ${px};
    padding-top: 64px;
    padding-bottom: 104px;
    position: relative;

    ::after {
        content: '';
        position: absolute;
        bottom: 64px;
        left: 0;
        height: 16px;
        right: 0;
        background: url(${dots}) repeat-x;
        margin-left: -44px;
    }

    @media (min-width: 1024px) {
        ${flexCenter};
        padding-top: 160px;
        padding-bottom: 77px;

        ::after {
            right: unset;
            width: 592px;
            margin-left: 0;
            bottom: 77px;
        }
    }

    @media (min-width: 1366px) {
        ::before {
            content: '';
            position: absolute;
            left: calc(50% - 380px);
            top: 192px;
            width: 451.18px;
            height: 456.52px;
            background: url(${circles}) no-repeat;
            background-size: contain;
            z-index: -1;
        }
    }
`;

const Wrapper = styled.div`
    max-width: ${({ theme }) => theme.sizes.md};
    border-radius: 15px;
    overflow: hidden;
    opacity: 0.9999;

    &,
    & > * {
        aspect-ratio: 343 / 392;
    }

    @media (min-width: 1024px) {
        margin-right: ${({ theme }) => theme.spacing.xxl};
        align-self: flex-start;

        &,
        & > * {
            aspect-ratio: 414 / 538;
            max-width: 414px;
        }
    }

    @media (min-width: 1366px) {
        position: relative;

        ::before,
        ::after {
            content: '';
            position: absolute;
            z-index: -1;
        }

        ::before {
            width: 140px;
            height: 140px;
            left: -53.27px;
            top: -53.27px;
            background: url(${orangeSquares}) no-repeat;
        }

        ::after {
            width: 118px;
            height: 104px;
            right: -66.66px;
            bottom: -68.78px;
            background: url(${purpleSquares}) no-repeat;
        }
    }
`;

const Intro = styled.div`
    margin: 24px 0;
    max-width: ${({ theme }) => theme.sizes.lg};

    > h3 {
        margin: 0 0 8px;
    }

    > p:last-of-type {
        margin: 0 0 20px;

        > b {
            font-weight: 600;
        }
    }

    @media (min-width: 1024px) {
        max-width: ${({ theme }) => theme.sizes.md};
        margin: 0 0 120px;
    }

    @media (min-width: 1150px) {
        margin-bottom: 150px;
    }
`;

const videoStyles = css`
    &,
    & > * {
        height: min(50vw, 367px);
        aspect-ratio: 343 / 242;
    }

    @media (min-width: 1024px) {
        &,
        & > * {
            height: min(50vw, 301px);
            aspect-ratio: 522 / 302;
        }
    }

    @supports (aspect-ratio: 1/1) {
        &,
        & > * {
            height: unset;
        }
    }
`;

export const AboutDr = () => {
    return (
        <StyledDr id="about-dr">
            <Wrapper>
                <StaticImage
                    src="../../../assets/images/dr-nuttall.jpg"
                    alt="doctor nuttall smiles"
                    width={520}
                />
            </Wrapper>
            <div>
                <Intro>
                    <SectionTag title="our doctor" bc="#B5E9E6" tc="#70928C" />
                    <h3>Dr. Kent Nuttall, DMD</h3>
                    <p>
                        This year, {new Date().getFullYear()}, marks
                        <b> {new Date().getFullYear() - 1997} years</b> in practice for Dr. Nuttall.
                        He takes pride in making every patient feel like family, calling every
                        patient the night before their first appointment to make sure they feel
                        comfortable about their visit.
                    </p>
                    <PrimaryBtn as={CLink} to={getSubNavLink('meet dr. kent nuttall')}>
                        More about our Doctor
                    </PrimaryBtn>
                </Intro>
                <Video videoId="5IkL8D1L0hA" css={videoStyles}>
                    <StaticImage
                        src="../../../assets/images/homepage/dr-thumbnail.jpg"
                        width={520}
                        alt="introduction to nuttall smiles one stop shop"
                    />
                </Video>
            </div>
        </StyledDr>
    );
};
