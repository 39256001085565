import { PrimaryBtn } from '@/Buttons/PrimaryBtn';
import CLink from '@/CLink';
import { getSubNavLink } from '@/layout/NavLinks';
import { px } from '@/layout/styles/classes';
import { caption, textSm } from '@/layout/styles/Typography';
import { SectionTag } from '@/SectionTag';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import {
    GatsbyImage,
    getImage,
    IGatsbyImageData,
    ImageDataLike,
    StaticImage,
    withArtDirection,
} from 'gatsby-plugin-image';

const StyledTechnology = styled.section`
    padding-top: 40px;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto auto;
    grid-row-gap: 32px;
    position: relative;

    @media (min-width: 1150px) {
        ${px};
        padding-top: 16px;
        display: flex;
        justify-content: end;
        min-height: 800px;
    }

    @media (min-width: 1600px) {
        min-height: 866px;
        justify-content: center;
    }
`;

const Intro = styled.div`
    ${px};
    max-width: ${({ theme }) => theme.sizes.lg};

    > h3 {
        margin: 0 0 8px;
    }

    > p:last-of-type {
        margin: 0 0 40px;
    }

    @media (min-width: 1150px) {
        padding: 0;
        max-width: 454px;
        padding-right: 130px;
        z-index: 5;
        align-self: start;
    }
`;

const List = styled.div`
    margin-bottom: 56px;
    width: 78.93vw;
    max-width: 475px;
    align-self: end;
    justify-self: center;
    grid-row: 2;
    grid-column: 1;
    z-index: 3;

    > div {
        margin-bottom: 40px;

        :last-of-type {
            margin-bottom: 0;
        }

        > p {
            color: ${({ theme }) => theme.colors.white};
            margin: 0 0 8px;

            :first-of-type {
                font-weight: 600;
            }

            :last-of-type {
                ${caption};
            }
        }

        > a {
            ${textSm};
            color: ${({ theme }) => theme.colors.orange2};
            text-decoration-line: underline;
            transition: 0.3s color ease-in-out;

            :hover,
            :focus {
                color: ${({ theme }) => theme.colors.orange4};
            }
        }
    }

    @media (max-width: 360px) {
        width: 91.46vw;
        margin-bottom: 40px;

        > div {
            margin-bottom: 32px;
        }
    }

    @media (min-width: 600px) {
        > div {
            margin-bottom: 48px;
        }
    }

    @media (min-width: 768px) {
        width: 400px;
        max-width: calc(100vw - (400px + ${({ theme }) => theme.spacing.lg}));
        justify-self: start;
        align-self: center;
        margin-bottom: 0;
        margin-left: calc(384px + ${({ theme }) => theme.spacing.lg});

        > div {
            p {
                :first-of-type {
                    color: ${({ theme }) => theme.colors.gray5};
                }

                :last-of-type {
                    color: ${({ theme }) => theme.colors.gray3};
                }
            }

            > a {
                color: ${({ theme }) => theme.colors.purple1};

                :hover,
                :focus {
                    color: ${({ theme }) => theme.colors.orange1};
                }
            }
        }
    }

    @media (min-width: 1150px) {
        margin-left: 20.8vw;
        width: 100%;
        max-width: 400px;
        margin-top: 16px;
        align-self: start;
        padding-left: 50px;

        > div {
            > a {
                :hover,
                :focus {
                    color: ${({ theme }) => theme.colors.orange2};
                }
            }
        }
    }

    @media (min-width: 1600px) {
        margin-left: 13.5vw;
        transform: translateX(5vw);
        margin-top: 48px;

        > div {
            > p:first-of-type {
                font-size: 1.25rem;
            }
        }
    }
`;

const tech1Styles = css`
    display: none;

    @media (min-width: 768px) {
        display: block;
        margin-top: 32px;
        max-width: 266px;
    }

    @media (min-width: 1150px) {
        transform: translateX(-20px);
    }
`;

const tech2Styles = css`
    display: none;

    @media (min-width: 768px) {
        display: block;
        position: absolute;
        bottom: 28%;
        left: -10px;
    }

    @media (min-width: 1150px) {
        left: 26%;
        bottom: 0;
    }

    @media (min-width: 1366px) {
        left: 32%;
    }
`;

const tech3Styles = css`
    display: none;

    @media (min-width: 768px) {
        display: block;
        position: absolute;
        left: 120px;
        bottom: 0;
    }

    @media (min-width: 1150px) {
        left: 44%;
    }

    @media (min-width: 1366px) {
        left: 48%;
    }

    @media (min-width: 1600px) {
        left: 49%;
    }
`;

const imgStyles = css`
    border-radius: 500px 500px 0px 0px;
    position: relative;
    grid-row: 2;
    grid-column: 1;
    height: calc(810px + 7vw);
    opacity: 0.9999;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: inset 9px 7px 16px rgba(0, 0, 0, 0.14),
            inset 19px 15px 24px rgba(65, 65, 72, 0.11);
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 2;
    }

    @media (max-width: 360px) {
        aspect-ratio: unset;
    }

    @media (min-width: 600px) {
        height: 890px;
        width: 600px;
        justify-self: center;
    }

    @media (min-width: 768px) {
        width: 384px;
        height: 784px;
        justify-self: start;
        box-shadow: inset 15px 10px 14px rgba(65, 65, 72, 0.09);

        ::before {
            background: transparent;
        }
    }

    @media (min-width: 1150px) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
    }

    @media (min-width: 1600px) {
        height: 850px;
    }
`;

const technologies = [
    {
        title: '3D Imaging',
        description:
            'With this technology, our dentist can capture high-quality images of your entire orofacial region, making it easy to understand your dental health and plan effective treatments.',
        link: getSubNavLink('3D Imaging'),
    },
    {
        title: 'Digital X-Rays',
        description:
            'Digital X-rays are one of the advanced diagnostic tools we use to provide you with the best possible care.',
        link: getSubNavLink('Digital X-Rays'),
    },
    {
        title: 'Waterlase® Dental Laser',
        description:
            'Laser dentistry involves the use of lasers to complete dental treatments more comfortably, quickly and effectively. ',
        link: getSubNavLink('Waterlase® Dental Laser'),
    },
    {
        title: 'Panorex Imaging',
        description:
            'Panorex imaging technology captures clear, sharp images of your teeth and other oral structures, including the jawbone, nerves, and soft tissues.',
        link: getSubNavLink('Panorex Imaging'),
    },
];

export const Technology = () => {
    type DataTypes = {
        tech1: ImageDataLike;
        tech2: ImageDataLike;
        tech3: ImageDataLike;
        dummy: ImageDataLike;
    };

    const data: DataTypes = useStaticQuery(graphql`
        query technologyPics {
            tech1: file(relativePath: { eq: "homepage/technology/tech-1.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 266, height: 267, layout: FIXED)
                }
            }
            tech2: file(relativePath: { eq: "homepage/technology/tech-2.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 228, height: 275, layout: FIXED)
                }
            }
            tech3: file(relativePath: { eq: "homepage/technology/tech-3.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 400, height: 400, layout: FIXED)
                }
            }
            dummy: file(relativePath: { eq: "dummy.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FIXED)
                }
            }
        }
    `);

    const tech1 = withArtDirection(getImage(data.tech1) as IGatsbyImageData, [
        {
            media: '(max-width: 767px)',
            image: getImage(data.dummy) as IGatsbyImageData,
        },
    ]);

    const tech2 = withArtDirection(getImage(data.tech2) as IGatsbyImageData, [
        {
            media: '(max-width: 767px)',
            image: getImage(data.dummy) as IGatsbyImageData,
        },
    ]);

    const tech3 = withArtDirection(getImage(data.tech3) as IGatsbyImageData, [
        {
            media: '(max-width: 767px)',
            image: getImage(data.dummy) as IGatsbyImageData,
        },
    ]);

    return (
        <StyledTechnology>
            <Intro>
                <SectionTag title="Technology" bc="#E5E7E0" tc="#8F9B7B" />
                <h3>Our Dental Technology</h3>
                <p>
                    Our state-of-the-art dental devices allow our dentist to provide you with
                    service that meets the highest standards in dentistry. Here is some of the top
                    dental technologies we carry and offer
                </p>
                <PrimaryBtn as={CLink} to={getSubNavLink('technology')}>
                    More about our Technology
                </PrimaryBtn>
                <GatsbyImage image={tech1} alt="biolase epicx machine" css={tech1Styles} />
            </Intro>
            <StaticImage
                src="../../../assets/images/homepage/technology/bg.jpg"
                alt="snow mountain"
                width={600}
                breakpoints={[250, 350, 450, 600]}
                css={imgStyles}
            />
            <GatsbyImage image={tech2} alt="biolase ezlase technology" css={tech2Styles} />
            <GatsbyImage image={tech3} alt="waterlase® dental laser" css={tech3Styles} />

            <List>
                {technologies.map((tech, i) => (
                    <div key={i}>
                        <p>{tech.title}</p>
                        <p>{tech.description}</p>
                        <CLink to={tech.link}>
                            Get to know our <span>{tech.title}</span>
                        </CLink>
                    </div>
                ))}
            </List>
        </StyledTechnology>
    );
};
