import { flexSpace, px } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import two from '../../../assets/images/homepage/affiliations/02.svg';
import three from '../../../assets/images/homepage/affiliations/03.svg';
import four from '../../../assets/images/homepage/affiliations/04.svg';
import five from '../../../assets/images/homepage/affiliations/05.svg';
import six from '../../../assets/images/homepage/affiliations/06.png';

const StyledAffiliations = styled.section`
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: ${({ theme }) => theme.colors.orange4};

    @media (min-width: 768px) {
        padding-top: 56px;
        padding-bottom: 56px;
    }
`;

const Logos = styled.div`
    ${px};
    ${flexSpace};
    overflow-y: auto;

    > img {
        margin-right: 28px;
        flex-shrink: 0;

        :nth-of-type(1) {
            width: 51px;
            aspect-ratio: 75 / 73;
        }

        :nth-of-type(2) {
            width: 76px;
            aspect-ratio: 109 / 51;
        }

        :nth-of-type(3) {
            width: 86px;
            aspect-ratio: 119 / 69;
        }

        :nth-of-type(4) {
            width: 100.31px;
            aspect-ratio: 139 / 43;
        }

        :last-of-type {
            margin-right: 0;
            width: 71.21px;
            aspect-ratio: 110 / 85;
        }
    }

    @media (min-width: 768px) {
        > img {
            margin-right: 56px;

            :nth-of-type(1) {
                width: 75px;
                aspect-ratio: 75 / 73;
            }

            :nth-of-type(2) {
                width: 109px;
                aspect-ratio: 109 / 51;
            }

            :nth-of-type(3) {
                width: 119px;
                aspect-ratio: 119 / 69;
            }

            :nth-of-type(4) {
                width: 139px;
                aspect-ratio: 139 / 43;
            }

            :last-of-type {
                width: 110px;
                aspect-ratio: 110 / 85;
            }
        }
    }

    @media (min-width: 1024px) {
        > img {
            margin-right: 0;
        }
    }

    @media (min-width: 1366px) {
        width: 80vw;
        margin: 0 auto;
    }
`;

export const Affiliations = () => {
    return (
        <StyledAffiliations>
            <Logos>
                <img src={two} alt="skcds" />
                <img src={three} alt="american dental association" />
                <img src={four} alt="american academy of facial esthetics" />
                <img src={five} alt="wsda" />
                <img src={six} alt="docs education" />
            </Logos>
        </StyledAffiliations>
    );
};
